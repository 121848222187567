
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import _ from "lodash";
import { endBatch, loadAllData, loadData } from "@/components/devices/helpers";
import Flags from "./batch/flags.vue";
const keys = ["COMMENT", "INTERVENTION", "author"];
@Component({
  components: { Flags },
  computed: {},
  async mounted() {
    this.$store.dispatch("loadBatches");
  },
  data: () => ({
    measurements_error: null,
    input: {
      COMMENT: null,
      INTERVENTION: null,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    },
    moment,
  }),
  methods: {
    async endBatch(batch) {
      try {
        console.warn("ending", batch);
        if (confirm("Are you sure you want to end batch?")) {
          await endBatch(batch.id, {
            ...this.$store.getters.tb_api_headers,
          });
          this.$store.dispatch("loadBatches");
        }
        console.warn("ended");
      } catch (error) {
        alert("Failed to end batch. Error: " + error);
      }
    },
    async loadData(batch) {
      try {
        console.warn("loading data", batch);
        await loadData(batch.id, {
          ...this.$store.getters.tb_api_headers,
        });
        this.$store.dispatch("loadBatches");
      } catch (error) {
        alert("Failed to load data. Error: " + error);
      }
    },
    async loadAllData(batch) {
      try {
        console.warn("loading data", batch);
        if (confirm("Thingsboard will have hard time, Are you sure?")) {
          await loadAllData({
            ...this.$store.getters.tb_api_headers,
          });
        }
      } catch (error) {
        alert("Failed to load data. Error: " + error);
      }
    },
  },
})
export default class ApEasyDens extends Vue {}
