var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"loading":_vm.$store.state.loading,"items":_vm.$store.state.all_batches.filter((x) => !x.endTime),"items-per-page":-1,"disable-pagination":true,"hide-default-footer":true,"dense":"","headers":[
        {
          text: 'start',
          align: 'start',
          value: 'startTime',
          width: 100,
        },
        {
          text: 'Batch',
          align: 'start',
          value: 'name',
          width: 100,
        },
        {
          text: 'Number',
          align: 'start',
          value: 'number',
          width: 20,
        },
        {
          text: 'flags',
          align: 'start',
          value: 'flags',
          width: 20,
        },
        {
          text: 'State',
          align: 'start',
          value: 'state',
          width: 50,
        },
        {
          text: 'Telemetry',
          align: 'start',
          value: 'telemetryLoaded',
          width: 50,
        },
        {
          text: 'Action',
          align: 'end',
          value: 'action',
          width: 50,
        },
      ]},scopedSlots:_vm._u([{key:"item.startTime",fn:function({ item }){return [_c('span',{attrs:{"title":_vm.moment(item.startTime)}},[_vm._v(" "+_vm._s(_vm.moment(item.startTime).format("YY-MM-DD HH:mm")))])]}},{key:"item.flags",fn:function({ item }){return [_c('Flags',{attrs:{"batch":item}})]}},{key:"item.telemetryLoaded",fn:function({ item }){return [(item.telemetryLoaded)?_c('v-btn',{attrs:{"title":`Telemetry was loaded ${new Date(item.telemetryLoaded)}`,"x-small":"","outlined":"","color":"green"},on:{"click":() => _vm.loadData(item)}},[_vm._v("Reload")]):_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"orange"},on:{"click":() => _vm.loadData(item)}},[_vm._v("Load")])]}},{key:"item.action",fn:function({ item }){return [(item.state !== 'ENDED')?_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"blue"},on:{"click":() => _vm.endBatch(item)}},[_vm._v("End Batch")]):_vm._e()]}}])})],1),_c('v-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"loading":_vm.$store.state.loading,"items":_vm.$store.state.all_batches,"items-per-page":-1,"disable-pagination":true,"hide-default-footer":true,"dense":"","headers":[
        {
          text: 'start',
          align: 'start',
          value: 'startTime',
          width: 100,
        },
        {
          text: 'Batch',
          align: 'start',
          value: 'name',
          width: 80,
        },
        {
          text: 'Number',
          align: 'start',
          value: 'number',
          width: 20,
        },
        // {
        //   text: 'Alicat',
        //   align: 'start',
        //   value: 'devices.ALICAT.name',
        //   width: 20,
        // },
        {
          text: 'flags',
          align: 'start',
          value: 'flags',
          width: 20,
        },
        {
          text: 'ABV',
          align: 'start',
          value: 'finalABV.value',
          width: 20,
        },
        {
          text: 'FG',
          align: 'start',
          value: 'finalGravity',
          width: 20,
        },
        {
          text: 'CO2L',
          align: 'start',
          value: 'finalSensorCO2L',
          width: 20,
        },
        // {
        //   text: 'Malt',
        //   align: 'start',
        //   value: 'malt.type',
        //   width: 20,
        // },
        // {
        //   text: 'Yeast',
        //   align: 'start',
        //   value: 'yeast.type',
        //   width: 20,
        // },
        {
          text: 'State',
          align: 'start',
          value: 'state',
          width: 50,
        },
        {
          text: 'Telemetry',
          align: 'start',
          value: 'telemetryLoaded',
          width: 50,
        },
      ]},scopedSlots:_vm._u([{key:"item.startTime",fn:function({ item }){return [_c('span',{attrs:{"title":_vm.moment(item.startTime)}},[_vm._v(" "+_vm._s(_vm.moment(item.startTime).format("YY-MM-DD HH:mm")))])]}},{key:"item.name",fn:function({ item }){return [_c('v-btn',{attrs:{"outlined":"","href":`/batch/${item.id}`,"x-small":""}},[_vm._v(" "+_vm._s(item.name)+" »")])]}},{key:"item.flags",fn:function({ item }){return [_c('Flags',{attrs:{"batch":item}})]}},{key:"item.finalSensorCO2L",fn:function({ item }){return [_vm._v(_vm._s(item.finalSensorCO2L && item.finalSensorCO2L.value ? item.finalSensorCO2L.value.toFixed(1) : "--"))]}},{key:"item.finalGravity",fn:function({ item }){return [_vm._v(_vm._s(item.finalGravity && item.finalGravity.value ? item.finalGravity.value.toFixed(5) : "--"))]}},{key:"item.telemetryLoaded",fn:function({ item }){return [(item.telemetryLoaded)?_c('v-btn',{attrs:{"title":`Telemetry was loaded ${new Date(item.telemetryLoaded)}`,"x-small":"","outlined":"","color":"green"},on:{"click":() => _vm.loadData(item)}},[_vm._v("Reload")]):_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"orange"},on:{"click":() => _vm.loadData(item)}},[_vm._v("Load")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }